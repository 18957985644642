<template>
  <div class="features">
    <section class="cover height-80 imagebg text-center" data-overlay="4">
      <div class="background-image-holder">
        <img alt="background" src="https://firebasestorage.googleapis.com/v0/b/bleep-bloop-app.appspot.com/o/photos%2Fschool-girl-wearing-headphones-using-laptop.png?alt=media&token=92abfd09-e5ea-45fd-9f95-01917400ed5d">
      </div>
      <div class="container pos-vertical-center">
        <div class="row">
          <div class="col-md-8">
            <div class="visible-xs visible-sm" style="height: 60px"></div>
            <h1>A Fresh Approach</h1>
            <p class="lead">
              Bleep Bloop offers innovative ways to work with your child overcome challenges and advance their understanding
            </p>
            <div class="modal-instance block">
              <div class="video-play-icon video-play-icon--sm modal-trigger"></div>
              <span><strong>See Bleep Bloop in action</strong>&nbsp;&nbsp;&nbsp;104 Seconds</span>
              <div class="modal-container">
                <div class="modal-content bg-dark" data-width="60%" data-height="60%">
                  <iframe data-src="https://www.youtube.com/embed/6p45ooZOOPo?autoplay=1" allowfullscreen="allowfullscreen"></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--
    <section class="cover bg--secondary text-center unpad--bottom">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="typed-headline">
              <span class="h2 inline-block">How It Works</span>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-md-12 col-lg-10">
            <img class="unmarg--bottom box-shadow-wide" alt="Image" src="/assets/img/device-4.png">
            <div class="bg--dark box-shadow-wide">
              <img class="unmarg--bottom" alt="Image" src="/assets/img/hero-1.jpg">
            </div>
          </div>
        </div>
      </div>
    </section>
    -->
  </div>
</template>
<script>
export default {
  name: 'Features',
  data() {
    return {
      
    }
  }
}
</script>
<style lang="scss">
  .features .background-image-holder {
    background: url('https://firebasestorage.googleapis.com/v0/b/bleep-bloop-app.appspot.com/o/photos%2Fschool-girl-wearing-headphones-using-laptop.png?alt=media&token=92abfd09-e5ea-45fd-9f95-01917400ed5d');
    opacity: 1;
  }
</style>