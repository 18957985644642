<template>
  <section class="height-100 text-center">
    <div class="container pos-vertical-center">
      <div class="row">
        <div class="col-md-7 col-lg-5">
          <h2>Recover your account</h2>
          <div v-if="success" class="alert bg--success">
            <div class="alert__body">
              <span>{{ success }}</span>
            </div>
          </div>
          <div v-else>
            <p class="lead">
              Enter your email address to send a recovery email.
            </p>
            <div v-if="error" class="alert bg--error">
              <div class="alert__body">
                <span>{{ error }}</span>
              </div>
            </div>
            <form @submit.prevent="sendRecoveryEmail()">
              <input v-model="email" type="text" placeholder="Email Address">
              <button class="btn btn--primary type--uppercase" type="submit">Recover Account</button>
            </form>
            <span class="type--fine-print block">Dont have an account yet?
              <a class="hyperlink" @click="go('Register')">Create account</a>
            </span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import firebase from 'firebase'
export default {
  name: 'Recover',
  data() {
    return {
      email: '',
      error: '',
      success: '',
      processing: false
    }
  },
  methods: {
    sendRecoveryEmail() {
      this.error = ''
      firebase.auth().sendPasswordResetEmail(this.email).then( fulfilled => {
        this.success = 'Recovery email sent. Please check your inbox.'
      }).catch( reason => {
        this.error = reason.message
      })
    }
  }
}
</script>
<style lang="scss">
  // .login .image-holder {
  //   background-image: url('https://firebasestorage.googleapis.com/v0/b/bleep-bloop-app.appspot.com/o/images%2Fbb-right-small.png?alt=media&token=4bf73bfa-7c89-4f41-af35-b8991b457f7f');
  //   background-color: #4a90e2;
  //   background-size: 50% !important;
  //   background-position: 50% 50%;
  //   background-repeat: no-repeat;
  //   height: 100%;
  //   min-height: 240px;
  // }
  .alert.bg--error .alert__body {
    color: #e23636;

  }
  .login .background-image-holder {
    background: url('https://firebasestorage.googleapis.com/v0/b/bleep-bloop-app.appspot.com/o/photos%2Fyoung-father-and-son-using-laptop-optimized.png?alt=media&token=7fa26d16-bb63-4ff6-a5e8-8f5f5ff911f3');
    opacity: 1;
  }
</style>