<template>
  <footer class="text-center-xs space--xs">
    <div class="container">
      <div class="row">
        <div class="col-sm-7">
          <ul class="list-inline">
            <li>
              <a @click="go('About')"><span class="h6 type--uppercase">About</span></a>
            </li>
            <li>
              <a @click="go('Careers')"><span class="h6 type--uppercase">Careers</span></a>
            </li>
            <li>
              <a @click="go('Support')"><span class="h6 type--uppercase">Support</span></a>
            </li>
          </ul>
        </div>
        <div class="col-sm-5 text-right text-center-xs">
          <ul class="social-list list-inline list--hover">
            <li><a href="https://twitter.com/bleepbloopapp">
              <span class="mdi mdi-twitter"></span>
            </a></li>
            <li><a href="https://instagram.com/bleepbloopapp">
              <span class="mdi mdi-instagram"></span>
            </a></li>
            <li><a href="https://discord.com/bleepbloopapp">
              <span class="mdi mdi-discord"></span>
            </a></li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-7">
          <span class="type--fine-print">Bleep Bloop&trade; © <span class="update-year">{{ new Date().getFullYear() }}</span>&nbsp;Farr Industries Corp.</span> <a class="type--fine-print" href="" @click="go('Privacy')">Privacy Policy</a> <a class="type--fine-print" href="" @click="go('Terms')">Terms of Service</a>
        </div>
        <div class="col-sm-5 text-right text-center-xs">
          <span class="type--fine-print">
            <router-link class="version pl-2" to="/changelog">{{ codename }}: {{ version }} {{ environment }}</router-link>
          </span>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
  export default {
    data: () => ({
  //     links: [
  //       'Home',
  //       // 'About Us',
  //       // 'Team',
  //       // 'Services',
  //       // 'Contact Us',
  //     ],
      codename: '',
      version: '',
      environment: '',
    }),
    created() {
      this.codename = process.env.VUE_APP_CODENAME
      this.version = process.env.VUE_APP_VERSION
      this.environment = process.env.VUE_APP_ENVIRONMENT
    },
  }
</script>