<template>
  <div class="parents">
    <section class="cover height-80 imagebg text-center" data-overlay="4">
      <div class="background-image-holder">
        <img alt="background" src="https://firebasestorage.googleapis.com/v0/b/bleep-bloop-app.appspot.com/o/photos%2Fhappy-family-with-teen-daughter-large.png?alt=media&token=c58d6271-9d0e-4718-9538-29283a89a0f3">
      </div>
      <div class="container pos-vertical-center">
        <div class="row">
          <div class="col-md-8">
            <div class="visible-xs visible-sm" style="height: 60px"></div>
            <h1>Hello, Parents!</h1>
            <p class="lead">
              Bleep Bloop offers innovative ways to work with your child overcome challenges and advance their understanding
            </p>
            <div class="modal-instance block">
              <div class="video-play-icon video-play-icon--sm modal-trigger"></div>
              <span><strong>See Bleep Bloop in action</strong>&nbsp;&nbsp;&nbsp;104 Seconds</span>
              <div class="modal-container">
                <div class="modal-content bg-dark" data-width="60%" data-height="60%">
                  <iframe data-src="https://www.youtube.com/embed/6p45ooZOOPo?autoplay=1" allowfullscreen="allowfullscreen"></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: 'Parents',
  data() {
    return {
      
    }
  }
}
</script>
<style lang="scss">
  .parents .background-image-holder {
    background: url('https://firebasestorage.googleapis.com/v0/b/bleep-bloop-app.appspot.com/o/photos%2Fhappy-family-with-teen-daughter-large.png?alt=media&token=c58d6271-9d0e-4718-9538-29283a89a0f3');
    opacity: 1;
  }
</style>