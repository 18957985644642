<template>
  <div class="nav-container">
    <nav class="bar bar-1" :class="headerClass">
      <div class="container">
        <div class="row">
          <!-- Branding -->
          <div class="branding-container" @click="go('Hello')">
            <img src="@/assets/images/bb-right.png" alt="logo">
            <span class="branding">{{ title }}</span>
          </div>
          <!-- Menu -->
          <div class="menu-container">
            <ul class="menu-horizontal text-left">
              <li v-for="item in items" :key="item.route">
                <a @click="go(item.route)">{{ item.name }}</a>
              </li>
              <!-- <li class="dropdown">
                <span class="dropdown__trigger">
                  Dropdown Slim
                </span>
                <div class="dropdown__container">
                  <div class="container">
                    <div class="row">
                      <div class="dropdown__content col-lg-2">
                        <ul class="menu-vertical">
                          <li>
                            <a href="#">Single Link</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </li> -->
            </ul>
          </div>
          <!-- Actions -->
          <div class="actions-container">
            <a v-if="user" class="btn btn--sm type--uppercase" @click="subnav = false; go('Account')">
              <span class="btn__text">Account</span>
            </a>
            <a v-if="!user" class="btn btn--sm type--uppercase" @click="subnav = false; go('Register')">
              <span class="btn__text">Create Account</span>
            </a>
            <a class="btn btn--sm btn--primary type--uppercase" @click="subnav = false; go('Play')">
              <span class="btn__text">Play Game</span>
            </a>
          </div>
          <div class="hamburger-container">
            <a @click="subnav = !subnav" class="hamburger-toggle" data-toggle-class="#menu1;hidden-xs hidden-sm"> <i class="icon icon--sm stack-interface stack-menu"></i> </a>
          </div>
        </div>
      </div>
      <div class="subnav" v-if="subnav">
        <div class="container">
          <ul class="menu-vertical text-left">
            <li v-for="item in items" :key="item.route">
              <a @click="subnav = false; go(item.route)">{{ item.name }}</a>
            </li>
          </ul>
          <!-- <div class="actions-container"> -->
            <a v-if="user" class="btn btn--sm type--uppercase" @click="subnav = false; go('Account')">
              <span class="btn__text">Account</span>
            </a>
            <a v-if="!user" class="btn btn--sm type--uppercase" @click="subnav = false; go('Register')">
              <span class="btn__text">Create Account</span>
            </a>
            <a class="btn btn--sm btn--primary type--uppercase" @click="subnav = false; go('Play')">
              <span class="btn__text">Play Game</span>
            </a>
          <!-- </div> -->
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'Header',
  props: ['user', 'clear'],
  data() {
    return {
      headerClass: '',
      brandingClass: 'dark',
      title: '<Bleep Bloop>',
      subnav: false,
      items: [
        { name: 'How It Works', route: 'Features' },
        { name: 'Teachers', route: 'Teachers' },
        { name: 'Parents', route: 'Parents' },
      ]
    }
  },
  mounted() {
    this.delta()
  },
  methods: {
    delta() {
      this.headerClass = this.clear ? 'clear' : 'solid'

    }
  },
  watch: {
    clear() {
      this.delta()
    },
    user() {
      // this.delta()
    }
  }
}
</script>
<style lang="scss">
  nav.clear {
    background: none;
    position: absolute;
    width: 100%;
    z-index: 99;
    .menu-horizontal li a {
      color: white !important;
    }
    .actions-container {
      .btn {
        border-color: rgba(255, 255, 255, 0.3) !important;
        .btn__text {
          color: white;
        }
      }
      .btn:hover {
        border-color: rgba(255, 255, 255, 0.7) !important;
      }
      .btn--primary {
        border: none !important;
      }
    }
    .branding {
      color: rgb(255 255 255 / 70%);
    }
    .hamburger-container {
      i {
        color: rgb(255 255 255 / 70%);
      }
      i:hover {
        color: white;
        cursor: pointer;
      }
    }
  }
  .branding-container {
    align-items: center;
    display: flex;
    flex: 1;
    img {
      margin: 0;
      // max-height: 1.85714286em;
      max-height: 4em;
      max-width: none;
    }
    .branding {
      font-family: Lato, sans-serif;
      font-size: 26px;
      color: #4c4c4c;
      display: flex;
      text-decoration: none;
      transition: color 200ms ease-out;
      align-items: center;
    }
  }
  .branding-container:hover {
    cursor: pointer;
    .branding.light {
      color: white; 
    }
  }
  .hamburger-container {
    align-items: center;
    display: flex;
  }
  .menu-container {
    align-items: center;
    display: none;
    justify-content: flex-end;
    margin: 0 20px;
    a:hover {
      cursor: pointer;
    }
  }
  .actions-container {
    align-items: center;
    display: none;
  }
  .bar .menu-horizontal {
    top: 0;
  }
  .subnav {
    background: white;
    margin-top: 20px;
    padding: 20px 0;
    -webkit-box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%); //, 0px 1px 10px 0px rgb(0 0 0 / 12%);
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%); //, 0px 1px 10px 0px rgb(0 0 0 / 12%);
    .menu-vertical li a {
      color: #666 !important;
      font-size: 1.2em;
    }
    .menu-vertical li a:hover {
      cursor: pointer;
    }
    .btn {
      width: 100%;
      margin: 0;
    }
    .btn--primary {
      margin-left: 0 !important;
      margin-top: 20px;
    }
  }
  @media (min-width: 576px) {
		
	}
	@media (min-width: 768px) {
		
	}
	@media (min-width: 992px) {
		.hamburger-container {
      display: none;
    }
    .menu-container, .actions-container {
      display: flex;
    }
	}
	@media (min-width: 1200px) {
    
	}
</style>