import Vue from 'vue'
import VueRouter from 'vue-router'
import firebase from 'firebase'
import db from '@/firebase/firestore'

import About from '../views/About.vue'
import Careers from '../views/Careers.vue'
import Changelog from '../views/Changelog.vue'
import Error from '../views/Error.vue'
import Features from '../views/Features.vue'
import Hello from '../views/Hello.vue'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Teachers from '../views/Teachers.vue'
import Parents from '../views/Parents.vue'
import Privacy from '../views/Privacy.vue'
import Recover from '../views/Recover.vue'
import Register from '../views/Register.vue'
import Support from '../views/Support.vue'
import Terms from '../views/Terms.vue'

import Account from '../views/Account.vue'
import Play from '../views/Play.vue'


Vue.use(VueRouter)

const routes = [
  /* ------------ Public Pages ------------ */
  {
    path: '/about',
    name: 'About',
    component: About,
  },
  {
    path: '/careers',
    name: 'Careers',
    component: Careers,
  },
  {
    path: '/changelog',
    name: 'Changelog',
    component: Changelog,
  },
  {
    path: '/hello',
    name: 'Hello',
    component: Hello
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/error/:error',
    name: 'Error',
    component: Error
  },
  {
    path: '/features',
    name: 'Features',
    component: Features
  },
  {
    path: '/parents',
    name: 'Parents',
    component: Parents
  },
  {
    path: '/privacy-policy',
    name: 'Privacy',
    component: Privacy
  },
  {
    path: '/recover',
    name: 'Recover',
    component: Recover
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/support',
    name: 'Support',
    component: Support
  },
  {
    path: '/teachers',
    name: 'Teachers',
    component: Teachers
  },
  {
    path: '/terms-of-service',
    name: 'Terms',
    component: Terms
  },
  
  /* ------------ Private Pages ------------ */
  {
    path: '/account',
    name: 'Account',
    component: Account,
    meta: {
      protected: true,
    }
  },
  {
    path: '/play',
    name: 'Play',
    component: Play,
    meta: {
      protected: true
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeResolve( (to,from,next) => {
  let user = firebase.auth().currentUser
  console.log(user)
  if (to.path == '/') {
    // ---------------------- Home Page ----------------------
    next({ name: 'Hello'})
  } else if (!to.name) {
    // ---------------------- Error Page ----------------------
    next({ name: 'Error', params: {'error': 404} })
  } else if ((to.name == 'Register' || to.name == 'Login') && user) {
    next({ name: 'Account'})
  } else if (to.matched.some(rec => rec.meta.protected) ) {
    // ---------------------- Protected Page ----------------------
    // We are attempting to load a private page. Authentication
    // is required.
    if (user && user.uid) { // user is logged in
      console.log('Authentication required', 'Authed: YES')
      // next({ name: 'Account'})
      next()
      // if (to.matched.some(rec => rec.meta.approved) ) {
  //       // ------------ Approved Page ------------
  //       // Approval is required so we query the database
  //       db.collection('users').doc(user.uid).get().then( snapshot => {
  //         if (snapshot.exists) {
  //           let data = snapshot.data()
  //           if (data.approved) {
  //             // ------------ Admin Page ------------
  //             // User has approval, we check for admin rights
  //             if (to.matched.some(rec => rec.meta.admin) ) {
  //               if (data.admin) {
  //                 next() // User is admin
  //               }
  //             } else {
                // next() // Admin not required
  //             }
  //           } else {
  //             console.log('Approval required, NOT approved')
  //             next({ name: 'Account' })
  //           }
  //         } else {
  //           console.log('Could not verify approval status.')
  //         }
  //       }).catch( err => {
  //         console.log('Could not retrieve approval status')
  //       })
  //     } else {
  //       next() // Approval not required
  //     }
    } else { 
      // ------------ User Not Authenticated ------------
      console.log('Authentication required', 'Authed: NO')
      next({ name: 'Login' })
    }
  } else {
    // ---------------------- Public Page ----------------------
    next() // Public Page
  }
})

export default router