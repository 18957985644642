<template>
  <div class="login">
    <section class="space--xs imageblock switchable feature-large">
      <div class="imageblock__content col-lg-5 col-md-4 pos-right">
        <div class="background-image-holder">
          <img alt="image" src="https://firebasestorage.googleapis.com/v0/b/bleep-bloop-app.appspot.com/o/photos%2Fyoung-father-and-son-using-laptop-optimized.png?alt=media&token=7fa26d16-bb63-4ff6-a5e8-8f5f5ff911f3">
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-lg-5 col-md-7">
            <h2>Welcome back!</h2>
            <hr class="short">
            <a @click="continueWithSocial('google')" class="btn block btn--icon bg--googleplus type--uppercase btn--disabled">
              <span class="btn__text">
                <i class="socicon-google"></i>
                Continue with Google
              </span>
            </a>
            <a @click="continueWithSocial('github')" class="btn block btn--icon bg--dark type--uppercase">
              <span class="btn__text">
                <i class="socicon-github"></i>
                Continue with Github
              </span>
            </a>
            <hr>
            <div v-if="error" class="alert bg--error">
              <div class="alert__body">
                <span>{{ error }}</span>
              </div>
            </div>
            <p>Continue with your Bleep Bloop account</p>
            <form @submit.prevent="continueWithEmailAndPassword()">
              <div class="row">
                <div class="col-md-12">
                  <input v-model="email" type="text" placeholder="Email" autocomplete="off">
                </div>
                <div class="col-md-12">
                  <input v-model="password" type="password" placeholder="Password" autocomplete="off">
                </div>
                <div class="col-md-12">
                  <button class="btn btn--primary type--uppercase" type="submit">Login</button>
                </div>
              </div>
            </form>
            <span class="type--fine-print block">Dont have an account yet?
              <a class="hyperlink" @click="go('Register')">Create account</a>
            </span>
            <span class="type--fine-print block">Forgot your username or password?
              <a class="hyperlink" @click="go('Recover')">Recover account</a>
            </span>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import firebase from 'firebase'
export default {
  name: 'Register',
  data() {
    return {
      email: '',
      password: '',
      error: '',
      processing: false
    }
  },
  methods: {
    continueWithEmailAndPassword() {
      console.log(this.email)
      this.error = ''
      this.processing = true
      firebase.auth().signInWithEmailAndPassword(this.email,this.password).then( credential => {
        this.go('Account')
      }).catch( reason => {
        this.error = reason.message
      })

    },
    continueWithSocial(provider) {
      if (provider == 'google') provider = new firebase.auth.GoogleAuthProvider()
      if (provider == 'github') provider = new firebase.auth.GithubAuthProvider()
      // provider.addScope('https://www.googleapis.com/auth/contacts.readonly')
      firebase.auth().languageCode = 'en'
      firebase.auth().signInWithPopup(provider)
      .then( credential => {
        this.go('Account')
        // console.log('bingo', credential)
        // if (credential.additionalUserInfo.isNewUser) {
        //   console.log('new!', credential)
        // }
        //   db.collection('users').add({
        //     uid: credential.user.uid,
        //     email: credential.user.email
        //   })
        //   .then( result => {
        //     this.go('Play') // Success!
        //   })
        //   .catch( err => {
        //     this.socialFeedback = err.message
        //     firebase.auth().signOut()
        //     this.overlay = false
        //   })
        // } else {
        //   // Existing User. This just becomes a standard login.
        //   this.go('Play')
        // }
      })
      .catch( err => {
        if (err.code == 'auth/popup-closed-by-user') {
          this.error = 'Authentication window closed.'
        } else {
          this.error = err.message
        }
        // this.overlay = false
      })
    },
  }
}
</script>
<style lang="scss">
  // .login .image-holder {
  //   background-image: url('https://firebasestorage.googleapis.com/v0/b/bleep-bloop-app.appspot.com/o/images%2Fbb-right-small.png?alt=media&token=4bf73bfa-7c89-4f41-af35-b8991b457f7f');
  //   background-color: #4a90e2;
  //   background-size: 50% !important;
  //   background-position: 50% 50%;
  //   background-repeat: no-repeat;
  //   height: 100%;
  //   min-height: 240px;
  // }
  .login .background-image-holder {
    background: url('https://firebasestorage.googleapis.com/v0/b/bleep-bloop-app.appspot.com/o/photos%2Fyoung-father-and-son-using-laptop-optimized.png?alt=media&token=7fa26d16-bb63-4ff6-a5e8-8f5f5ff911f3');
    opacity: 1;
  }
</style>