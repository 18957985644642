<template>
  <div class="about">
    <!-- ------------ Our Story ------------ -->
    <section class="cover height-80 imagebg text-center" data-overlay="6">
      <div class="background-image-holder" style="background: url('https://firebasestorage.googleapis.com/v0/b/bleep-bloop-app.appspot.com/o/photos%2Fteacher-shows-children-technology.png?alt=media&token=d1af1e3c-bbfe-4ae7-925d-bc826f6121e0'); opacity: 1;">
        <img alt="background" src="https://firebasestorage.googleapis.com/v0/b/bleep-bloop-app.appspot.com/o/photos%2Fteacher-shows-children-technology.png?alt=media&token=d1af1e3c-bbfe-4ae7-925d-bc826f6121e0">
      </div>
      <div class="container pos-vertical-center">
        <div class="row">
          <div class="col-md-6">
            <span class="h1">Engineering the Future</span>
            <p class="lead">
              We're building the next generation of learning tools, for the next generation of engineers
            </p>
            <div class="modal-instance block">
              <div class="video-play-icon video-play-icon--sm modal-trigger" data-modal-index="2"></div>
              <span>
                <strong>Watch our story</strong>
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Golden Circle -->
    <section class="text-center">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-10 col-lg-8">
            <h2>We believe in a brighter future through technology</h2>
            <p class="lead">
              We're passionate about teaching children the skills they'll need to build the future.
              We teach children technology through the creative exploration of possibilities; to naturally encounter challenges as a means of introducing solutions.
              We build fun and engaging games into complete learning systems.
            </p>
          </div>
        </div>
      </div>
    </section>

    <!-- Pillars -->
    <section class="bg--secondary">
      <div class="container">
        <div class="row">
          <div class="col-md-4">
            <div class="feature">
              <h4>Creativity</h4>
              <p>
                We seek to learn as much from our students as they do from our games. The core of our business is built on trying new ideas with fun and energy to find what works.
              </p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="feature">
              <h4>Drive</h4>
              <p>
                We are passionate about helping children florish along their path to understanding. We are focused on delivering joyful experiences that work.
              </p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="feature">
              <h4>Quality</h4>
              <p>
                We are committed to quality in everything that we do. We sweat the little big details so that our students receive nothing but the best possible experience.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Our Team -->
    <section class="text-center">
      <div class="container">
        <div class="row">
          <div class="col-md-10 col-lg-8">
            <h2>Meet Our Makers</h2>
            <p class="lead">
              A small team with big ambitions. Check out the talent behind the scenes.
            </p>
          </div>
        </div>
      </div>
    </section>
    <!-- Aaron -->
    <section class="switchable feature-large">
      <div class="container">
        <div class="row justify-content-around">
          <div class="col-md-6">
            <img alt="Image" class="border--round" src="https://firebasestorage.googleapis.com/v0/b/bleep-bloop-app.appspot.com/o/photos%2Favatar-aaron-wide.png?alt=media&token=196b87a7-aeac-461c-8ca8-fc1e69e7740f">
          </div>
          <div class="col-md-6 col-lg-5">
            <div class="switchable__text">
              <div class="text-block">
                <h2>Aaron Farr</h2>
                <span>Co-Founder &amp; Engineering Lead</span>
              </div>
              <!-- <p class="lead">
                Launching an attractive and scalable website quickly and affordably is important for modern startups — Stack offers massive value without looking 'bargain-bin'.
              </p> -->
              <ul class="social-list list-inline list--hover">
                <li>
                  <a href="#">
                    <i class="socicon socicon-mail icon icon--xs"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i class="socicon socicon-discord icon icon--xs"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i class="socicon socicon-google icon icon--xs"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i class="socicon socicon-twitter icon icon--xs"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i class="socicon socicon-instagram icon icon--xs"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Ronan -->
    <section class="switchable switchable--switch feature-large">
      <div class="container">
        <div class="row justify-content-around">
          <div class="col-md-6">
            <img alt="Image" class="border--round" src="https://firebasestorage.googleapis.com/v0/b/bleep-bloop-app.appspot.com/o/photos%2Favatar-ronan.png?alt=media&token=72ded0e2-1f7c-48ac-8e99-cc1a5d40c61b">
          </div>
          <div class="col-md-6 col-lg-5">
            <div class="switchable__text">
              <div class="text-block">
                <h2>Ronan Farr</h2>
                <span>Co-Founder &amp; Creative Lead</span>
              </div>
              <!-- <p class="lead">
                Launching an attractive and scalable website quickly and affordably is important for modern startups — Stack offers massive value without looking 'bargain-bin'.
              </p> -->
              <ul class="social-list list-inline list--hover">
                <li>
                  <a href="#">
                    <i class="socicon socicon-mail icon icon--xs"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i class="socicon socicon-discord icon icon--xs"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i class="socicon socicon-google icon icon--xs"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i class="socicon socicon-twitter icon icon--xs"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i class="socicon socicon-instagram icon icon--xs"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Eduardo -->	
    <section class="switchable feature-large">
      <div class="container">
        <div class="row justify-content-around">
          <div class="col-md-6">
            <img alt="Image" class="border--round" src="https://firebasestorage.googleapis.com/v0/b/bleep-bloop-app.appspot.com/o/photos%2Favatar-eduardo.png?alt=media&token=abdffffa-d454-4a21-9f23-3d850d258e16">
          </div>
          <div class="col-md-6 col-lg-5">
            <div class="switchable__text">
              <div class="text-block">
                <h2>Edoardo Zuliani</h2>
                <span>Art Lead</span>
              </div>
              <!-- <p class="lead">
                Launching an attractive and scalable website quickly and affordably is important for modern startups — Stack offers massive value without looking 'bargain-bin'.
              </p> -->
              <ul class="social-list list-inline list--hover">
                <li>
                  <a href="#">
                    <i class="socicon socicon-mail icon icon--xs"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i class="socicon socicon-discord icon icon--xs"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i class="socicon socicon-google icon icon--xs"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i class="socicon socicon-twitter icon icon--xs"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i class="socicon socicon-instagram icon icon--xs"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Gabrielle -->
    <section class="switchable switchable--switch feature-large">
      <div class="container">
        <div class="row justify-content-around">
          <div class="col-md-6">
            <img alt="Image" class="border--round" src="https://firebasestorage.googleapis.com/v0/b/bleep-bloop-app.appspot.com/o/photos%2Favatar-gabrielle.png?alt=media&token=e46599f2-89ac-4bb9-a0ad-27b2299d29b5">
          </div>
          <div class="col-md-6 col-lg-5">
            <div class="switchable__text">
              <div class="text-block">
                <h2>Gabrielle Vogel</h2>
                <span>Business Development Lead</span>
              </div>
              <!-- <p class="lead">
                Launching an attractive and scalable website quickly and affordably is important for modern startups — Stack offers massive value without looking 'bargain-bin'.
              </p> -->
              <ul class="social-list list-inline list--hover">
                <li>
                  <a href="#">
                    <i class="socicon socicon-mail icon icon--xs"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i class="socicon socicon-discord icon icon--xs"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i class="socicon socicon-google icon icon--xs"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i class="socicon socicon-twitter icon icon--xs"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i class="socicon socicon-instagram icon icon--xs"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
      
      <!-- <div class="row">
          <div class="col-md-2">
            <div class="feature feature-8">
              <img alt="Image" src="https://firebasestorage.googleapis.com/v0/b/bleep-bloop-app.appspot.com/o/photos%2Favatar-eduardo.png?alt=media&token=abdffffa-d454-4a21-9f23-3d850d258e16" class="avatar">
              <h5>Aaron Farr</h5>
              <span>Head of Engineering</span>
            </div>
          </div>
          <div class="col-md-2">
            <div class="feature feature-8">
              <img alt="Image" src="https://firebasestorage.googleapis.com/v0/b/bleep-bloop-app.appspot.com/o/photos%2Favatar-ronan.png?alt=media&token=fc635218-827a-4f09-bbaf-4e3d71bed5c5" class="avatar">
              <h5>Ronan Farr</h5>
              <span>Head of Creativity</span>
            </div>
          </div>
          <div class="col-md-2">
            <div class="feature feature-8">
              <img alt="Image" src="https://firebasestorage.googleapis.com/v0/b/bleep-bloop-app.appspot.com/o/photos%2Favatar-eduardo.png?alt=media&token=fb278c5f-a238-46cd-9f7c-a8ffcdfeb8d9" class="avatar">
              <h5>Edoardo Zuliani</h5>
              <span>Art Director</span>
            </div>
          </div>
        </div> -->

    <!-- Office -->
    <section>
      <div class="container">
        <div class="row">
          <div class="col">
            <img alt="Image" src="https://firebasestorage.googleapis.com/v0/b/bleep-bloop-app.appspot.com/o/photos%2Fabout-office.png?alt=media&token=b814ef35-21de-46e2-880a-1100603b0fa7">
          </div>
        </div>
      </div>
    </section>
    
    <!-- Careers -->
    <section class="text-center">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6">
            <div class="cta">
              <h2>We're always looking for talent</h2>
              <p class="lead">
                If you think you'd be a good fit for the team please send us a link to your resumé or portfolio for consideration.
              </p>
              <a class="btn btn--primary type--uppercase" href="/careers">
                <span class="btn__text">
                  See Job Openings
                </span>
              </a>
            </div>
          </div>
        </div>
        <!--end of row-->
      </div>
      <!--end of container-->
    </section>
  </div>
</template>
<script>
export default {
  name: 'About',
  data() {
    return {
      
    }
  }
}
</script>
<style lang="scss">
  .about .background-image-holder {
    background: url('/assets/img/about-hero.png');
    opacity: 1;
  }
</style>