import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import firebase from '@/firebase/init'

Vue.config.productionTip = false

Vue.mixin({
  methods: {
    go: route => {
      if (router.history.current.name != route) {
        router.push({ name: route }).catch(() => {})
        window.scrollTo(0,0)
      }
    },
  }
})

let app = null

firebase.auth().onAuthStateChanged(() => {
  if(!app) {
    app = new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  }
})