<template>
  <div class="changelog">
    <section class="text-center">
      <div class="container">
        <div class="row">
          <div class="col-md-10 col-lg-8">
            <h2>Meet Our Makers</h2>
            <p class="lead">
              A small team with big ambitions. Check out the talent behind the scenes.
            </p>
          </div>
        </div>
      </div>
    </section>
    <!--  -->
    <section class="switchable">
      <div class="container">
        <div class="row justify-content-between">
          <div class="col-md-12 switchable__text">
            <ul class="accordion accordion-1">
              <li v-for="(log, i) in logs" :key="i" @click.prevent="panel = i" :class="i == panel ? 'active' : ''">
                <div class="accordion__title">
                  <span class="h5">Version: {{ log.version }}</span>
                </div>
                <div class="accordion__content">
                  <p class="lead">{{ log.description }}</p>
                  <br v-if="log.description">
                  <ul>
                    <li class="active" v-for="(feature, n) in log.features" :key="'feature'+i+'-'+n">
                      <span v-if="i == 0 && feature.toLowerCase().indexOf('bug') >= 0" class="mdi mdi-star-circle-outline"></span> 
                      <span v-else-if="i == 0 && feature.toLowerCase().indexOf('bug') < 0" class="mdi mdi-check"></span> 
                      <span v-else-if="i > 0 && feature.toLowerCase().indexOf('bug') >= 0" class="mdi mdi-bug-check"></span> 
                      <span v-else class="mdi mdi-check-bold"></span> 
                      {{ feature }}
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'ChangeLog',
  data() {
    return {
      panel: 0,
      logs: [
        // ------------ Future Release ------------
        // {
        //   version: '0.2.5',
        //   description: '',
        //   features: [
        //     'Dashboard',
        //     'Account page',
        //     'Stripe integration',
        //     'Child accounts',
        //     'Classroom create'
        //   ]
        // },
        // ------------ In Development (Next Release) ------------
        {
          version: '0.2.5',
          description: 'Account details',
          features: [
            'Account: Verify',
            'User table',
            'Account: Donate',
            'Account: Pro Membership',
            'Stripe integration',
            'Child accounts',
            'Classroom create'
          ]
        },
        // ------------ Previous Releases ------------
        {
          version: '0.2.4',
          description: 'Website overhaul',
          features: [
            'Parents: Basic content',
            'Teachers: Basic content',
            'Features: Basic content',
            'Contact form',
            'Registration',
            'Login',
          ]
        },
        {
          version: '0.2.3',
          description: '',
          features: [
            'Separated game engine to developers site',
            'New web design and scaffolding'
          ]
        },
        {
          version: '0.2.2',
          description: '',
          features: [
            'Engine: removed graphics performance testing',
            'Engine: load basic level',
          ]
        },
        {
          version: '0.2.1',
          description: '',
          features: [
            'Engine: graphics performance testing',
              'method 1: sprite background - 5.5',
              'method 2: canvas background - 5.7',
              'method 3: image background - 5.1',
          ]
        },
        {
          version: '0.2.0',
          description: '',
          features: [
            'Engine: p5 integration',
            'Engine: camera scrolling',
            'Engine: window resizing',
          ]
        },
        {
          version: '0.1.9',
          description: '',
          features: [
            'Engine: Game Engine UI',
          ]
        },
        {
          version: '0.1.8',
          description: '',
          features: [
            'Editor: Export images',
            'Editor: Grid Size/Resize',
            'Editor: Export spritesheet',
          ]
        },
        {
          version: '0.1.7',
          description: '',
          features: [
            'Editor: Save Level',
            'Editor: Load Level',
          ]
        },
        {
          version: '0.1.6',
          description: '',
          features: [
            'Editor: Brush drawing',
            'Editor: Grid Toggle',
            'Editor: Added Spritesheet (Serene Village)',
            'Editor: Disabled user select',
          ]
        },
        {
          version: '0.1.5',
          description: '',
          features: [
            'Editor: Complete overhaul and optimization',
            'p5 preload spritesheets',
            'copy sprite to sprites[]',
            'canvas grid + camera'
          ]
        },
        {
          version: '0.1.4',
          description: '',
          features: [
            'Editor: Basic layers'
          ]
        },
        {
          version: '0.1.3',
          description: '',
          features: [
            'Editor: Drawing from multiple spritesheets'
          ]
        },
        {
          version: '0.1.2',
          description: '',
          features: [
            'Editor: Loading palette'
          ]
        },
        {
          version: '0.1.1',
          description: '',
          features: [
            'Major Rework',
            'Basic game engine',
            'Creating advanced level editor',
            'New website design'
          ]
        },
        {
          version: '0.0.8',
          description: '',
          features: [
            'Implement background + clipping'
          ]
        },
        {
          version: '0.0.7',
          description: '',
          features: [
            'added background selection',
            'added screenshot feature',
          ]
        },
        {
          version: '0.0.6',
          description: '',
          features: [
            'bug: ghosting issue',
            'added authentication',
            'added approval system',
            'added changelog'
          ]
        },
        {
          version: '0.0.5',
          description: '',
          features: [
            'Added graphics to physics objects'
          ]
        },
      ]
    }
  }
}
</script>
<style scoped lang="scss">
  li.active .accordion__content {
    border: 1px solid #dadada;
    padding: 20px;
    border-radius: 5px;
    background: #f8f8f8;
  }
</style>