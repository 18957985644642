import firebase from 'firebase'

// const firebaseConfig = {
//   apiKey: "AIzaSyDRYNyYLh-xaSnr3iTRyiEaRISQJGB6vRg",
//   authDomain: "bleep-bloop-development.firebaseapp.com",
//   projectId: "bleep-bloop-development",
//   storageBucket: "bleep-bloop-development.appspot.com",
//   messagingSenderId: "159112565048",
//   appId: "1:159112565048:web:7ae17eabfb7f3a1463b1cd"
// }

const firebaseConfig = { // Initialize Firebase
  apiKey: "AIzaSyABT_ABsBw9XuSC0fFr7wLG51TrhZQz_gw",
  authDomain: "bleep-bloop-app.firebaseapp.com",
  databaseURL: "https://bleep-bloop-app.firebaseio.com",
  projectId: "bleep-bloop-app",
  storageBucket: "bleep-bloop-app.appspot.com",
  messagingSenderId: "561289855815",
  appId: "1:561289855815:web:6566b5ad217b2496d18262",
  measurementId: "G-GBDTCQRR3Y"
}

const firebaseApp = firebase.initializeApp(firebaseConfig)
export default firebaseApp