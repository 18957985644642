<template>
  <div class="register">
    <section class="space--xs imageblock switchable feature-large">
      <div class="imageblock__content col-lg-5 col-md-4 pos-right">
        <div class="background-image-holder">
          <img alt="image" src="https://firebasestorage.googleapis.com/v0/b/bleep-bloop-app.appspot.com/o/photos%2Fyoung-father-and-son-using-laptop-optimized.png?alt=media&token=7fa26d16-bb63-4ff6-a5e8-8f5f5ff911f3">
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-lg-5 col-md-7">
            <h2>Create an account</h2>
            <span>Already have an account? <a class="hyperlink" @click="go('Login')">Sign In</a></span>
            <hr class="short">
            <a @click="continueWithSocial('google')" class="btn block btn--icon bg--googleplus type--uppercase btn--disabled">
              <span class="btn__text">
                <i class="socicon-google"></i>
                Continue with Google
              </span>
            </a>
            <a @click="continueWithSocial('github')" class="btn block btn--icon bg--dark type--uppercase">
              <span class="btn__text">
                <i class="socicon-github"></i>
                Continue with Github
              </span>
            </a>
            <hr>
            <div v-if="error" class="alert bg--error">
              <div class="alert__body">
                <span>{{ error }}</span>
              </div>
            </div>
            <form @submit.prevent="registerWithEmailAndPassword()">
              <div class="row">
                <div class="col-12">
                  <input v-model="email" :disabled="processing" type="email" placeholder="Email Address">
                </div>
                <div class="col-12">
                  <input v-model="password" :disabled="processing" type="password" placeholder="Password">
                </div>
                <div class="col-12">
                  <button :disabled="processing" type="submit" class="btn btn--primary">Create Account</button>
                </div>
                <hr>
                <div class="col-12">
                  <span class="type--fine-print">By signing up, you agree to the <a @click="go('Terms')" class="hyperlink">Terms of Service</a></span>                  
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import firebase from 'firebase'
export default {
  name: 'Register',
  data() {
    return {
      email: '',
      password: '',
      processing: false,
      error: ''
    }
  },
  methods: {
    registerWithEmailAndPassword() {
      this.processing = true
      this.error = ''
      firebase.auth().createUserWithEmailAndPassword(this.email, this.password).then( result => {
        this.go('Account')
      })
      .catch( reason => {
        console.log(reason)
        this.error = reason.message
        this.processing = false
      })
    },
    continueWithSocial(provider) {
      if (provider == 'google') provider = new firebase.auth.GoogleAuthProvider()
      if (provider == 'github') provider = new firebase.auth.GithubAuthProvider()
      // provider.addScope('https://www.googleapis.com/auth/contacts.readonly')
      firebase.auth().languageCode = 'en'
      firebase.auth().signInWithPopup(provider)
      .then( credential => {
        this.go('Account')
      })
      .catch( err => {
        if (err.code == 'auth/popup-closed-by-user') {
          this.error = 'Authentication window closed.'
        } else {
          this.error = err.message
        }
      })
    },
  }
}
</script>
<style lang="scss">
  .register .background-image-holder {
    background: url('https://firebasestorage.googleapis.com/v0/b/bleep-bloop-app.appspot.com/o/photos%2Fyoung-father-and-son-using-laptop-optimized.png?alt=media&token=7fa26d16-bb63-4ff6-a5e8-8f5f5ff911f3');
    opacity: 1;
  }
</style>