<template>
  <div class="home">
    <!-- Hero -->
    <section class="switchable bg--primary">
      <div class="container">
        <div class="row justify-content-between">
          <div class="col-lg-5 col-md-7">
            <div class="mt--2">
              <h1>Complete system for teaching kids to code</h1>
              <p class="lead">Proven by science, loved by kids</p>
              <a class="btn btn--primary type--uppercase" @click="go('Play')">
                <span class="btn__text">Launch the Game</span>
              </a>
              <span class="block type--fine-print">It's completely free. Seriously.</span>
            </div>
          </div>
          <div class="col-lg-7 col-md-5 col-12">
            <img alt="Image" src="/assets/img/device-2.png"></div>
          </div>
        </div>
    </section>
  </div>
</template>

<script>

export default {
  name: 'Hello',
  data() {
    return {
      
    }
  }
}
</script>