<template>
  <div class="support">
    <section class="text-center height-50">
      <div class="container pos-vertical-center">
        <div class="row">
          <div class="col-md-8 col-lg-6">
            <h1>We're here to help!</h1>
            <p class="lead">
              Is something not quite working correctly? Let us know and we'll do our best to get it sorted out.
            </p>
          </div>
        </div>
      </div>
    </section>
    <!-- Contact Form -->
    <section class="text-center">
      <div class="container">
        <div class="row justify-content-center no-gutters">
          <div class="col-md-8 col-lg-7">
            <div v-if="success" class="alert bg--success">
              <!-- Success Alert -->
              <div class="alert__body">
                <span>Thanks for your enquiry, we'll investigate and get back to you as soon as possible.</span>
              </div>
            </div>
            <!--  -->
            <div v-if="error" class="alert bg--error">
              <div class="alert__body">
                <span>Something went wrong. Your message was not received please try again.</span>
              </div>
              <div class="alert__close">
                &times;
              </div>
            </div>
            <form v-if="!success" @submit="send()"  class="text-left form-email row mx-0" data-success="Thanks for your enquiry, we'll be in touch within 5 business days." data-error="Please fill in all fields correctly.">
              <div class="col-md-6">
                <label>Your Name:</label>
                <input v-model="name" type="text" name="name" class="validate-required">
              </div>
              <div class="col-md-6">
                <label>Email Address:</label>
                <input v-model="email" type="email" name="email" class="validate-required validate-email">
              </div>
              <div class="col-md-12">
                <label>Message:</label>
                <textarea v-model="message" rows="6" name="Message" class="validate-required"></textarea>
              </div>
              <div class="col-md-5 col-lg-4">
                <button :disabled="success" type="submit" class="btn btn--primary type--uppercase">Send Enquiry</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
    <section class="text-center imagebg" data-gradient-bg="#4876BD,#5448BD,#8F48BD,#BD48B1">
      <div class="container">
        <div class="row">
          <div class="col-md-8 col-lg-6">
            <div class="cta">
              <h2>Join the Community on Discord</h2>
              <a class="btn btn--primary btn--discord type--uppercase" _target="blank" href="https://discord.com/bleepbloopapp">
                <span class="btn__text">
                  <span class="mdi mdi-discord mr-2"></span>
                  Launch Discord
                </span>
                <span class="label label--discord">external</span>
              </a>
              <p class="type--fine-print">or you can <a href="#">send us a message</a></p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- <div class="modal-container" :class="sending ? 'modal-active' : ''">
      <div class="modal-content" data-width="80" data-height="80"></div>
    </div> -->
  </div>
</template>
<script>
import axios from 'axios'
export default {
  name: 'Support',
  data() {
    return {
      name: '',
      email: '',
      message: '',
      success: false,
      error: false,
      sending: false,
    }
  },
  methods: {
    send() {
      this.success = false
      this.error = false
      var mailformat = /\S+@\S+\.\S+/
      if (this.name && this.email && this.message && this.email.match(mailformat)) {
        this.sending = true
        let endpoint = 'https://us-central1-bleep-bloop-app.cloudfunctions.net/sendEmail'
        let data = {
          'name': this.name,
          'email': this.email,
          'message': this.message,
        }
        axios.post(
          endpoint,
          data
        ).then( response => {
          this.sending = false
          if (response && response.data && response.data.success) {
            this.success = true
            // window.mr.forms.showFormSuccess($('.form-success'), $('.form-error'), 1000, 5000, 500)
          } else {
            this.error = true
          }
        }).catch( error => {
          this.sending = false
          this.error = true
        })
      } else {
        // window.mr.forms.showFormError($('.form-success'), $('.form-error'), 1000, 5000, 500)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .btn--discord {
    background: #5865f2;
    border-color: #5865f2;
  }
  .label--discord {
    background: white;
    color: black !important;
  }
  .alert.bg--success {
    .alert__body {
      color: green !important;
    }
  }
</style>