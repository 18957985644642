<template>
  <div id="app">
    <Header :clear="clear" :user="user" />
    <div class="main-container">
			<router-view :user="user" />
			<Footer />
		</div>
  </div>
</template>

<script>
import firebase from 'firebase'
import db from '@/firebase/firestore'
import Header from './components/Header'
import Footer from './components/Footer'
export default {
  name: 'App',
  components: {
    Header,
    Footer
  },
  data: () => ({
    user: null,
    route: '',
    clear: true
  }),
  created() {
    this.delta()
    // ------------ Auth Listener ------------
    firebase.auth().onAuthStateChanged( firebaseUser => {
      if (firebaseUser) {
        this.user = firebaseUser
        // ------------ Firestore User Listener ------------
//         db.collection('users').doc(firebaseUser.uid).onSnapshot( snapshot => {
//           this.user = snapshot.data()
//         })
//         // ------------ Handle User Authentication ------------
//         db.collection('users').doc(firebaseUser.uid).get().then( doc => {
//           let data = doc.data()
//           if (doc.exists) {
//             // ------------ Login ------------
//             this.user = doc.data()
//             if (this.route == 'Login') this.go('Play')
//           } else {
//             // ------------ Registration ------------
//             let user = {
//               uid: firebaseUser.uid,
//               profile: {
//                 name: firebaseUser.displayName,
//                 email: firebaseUser.email,
//                 photo: firebaseUser.photoURL,
//                 avatar: firebaseUser.photoURL,
//                 phone: firebaseUser.phoneNumber
//               },
//               verified: firebaseUser.emailVerified,
//               approved: false
//             }
//             db.collection('users').doc(firebaseUser.uid).set(user).then( result => {
//               this.log("User Registered")
//               this.user = user
//               this.go('Play')
//             }).catch( err => {
//               this.log("User Registration Failed.")
//               this.user = null
//             })
//           }
//         })
      } else {
        // ------------ Logged Out ------------
        if (this.user) {
          // console.log('User Logged Out')
          this.user = null
          this.go('Login')
        }
      }
    })
  },
  mounted() {
    const plugin = document.createElement("script");
    plugin.setAttribute("src", "/assets/js/scripts.js");
    plugin.async = true;
    document.head.appendChild(plugin);
  },
  methods: {
    delta() {
      this.route = this.$route.name
      let clear = ['Hello','Parents','Teachers','Features','About'] // 'Login', 'Register', 'Features', 'Play']
      this.clear = clear.indexOf(this.route) == -1 ? false : true;
    }
  },
  watch: {
    $route() {
      this.delta()
    }
  }
};
</script>

<style lang="scss">
  a {
    cursor: pointer;
  }
  a.hyperlink {
    color: #4a90e2 !important;
    text-decoration: underline !important;
  }
  a.hyperlink:hover {
    color: #2275d7 !important;
  }
  .alert.bg--error .alert__body {
    color: #e23636;
  }
  .alert.bg--success .alert__body {
    color: green;
  }
	@media (min-width: 576px) {
		
	}
	@media (min-width: 768px) {
		
	}
	@media (min-width: 992px) {
		
	}
	@media (min-width: 1200px) {
		
	}
</style>