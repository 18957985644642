<template>
  <section class="bg--secondary space--sm">
    <div class="container">
      <div class="row">
        <div class="col-lg-4">
          <div class="boxed boxed--lg boxed--border">
            <div class="text-block text-center">
              <img alt="avatar" :src="user.photoURL" class="image--sm">
              <span class="h5">{{ user.displayName }}</span>
              <span>Pro Account</span>
              <span class="label">Pro</span>
            </div>
            <hr>
            <div class="text-block">
              <ul class="menu-vertical">
                <li>
                  <a @click="tab=0">Profile</a>
                </li>
                <li>
                  <a @click="tab=1">Email Notifications</a>
                </li>
                <li>
                  <a @click="tab=2">Billing Details</a>
                </li>
                <li>
                  <a @click="tab=3">Password</a>
                </li>
                <li>
                  <a @click="tab=4">Delete Account</a>
                </li>
                <li>
                  <a @click="logout()">Signout</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-8">
          <div class="boxed boxed--lg boxed--border">
            <div v-if="tab == 0" class="account-tab">
              <h4>Profile</h4>
              <form @submit.prevent="">
                <div class="row">
                  <div class="col-md-6">
                    <label>Real Name:</label>
                    <input type="text" name="name" value="Lucas Banks">
                  </div>
                  <div class="col-md-6">
                    <label>Display Name:</label>
                    <input type="text" name="display-name" value="l_banks">
                  </div>
                  <div class="col-md-12">
                    <label>Email Address:</label>
                    <input type="email" name="email" value="l_banks@stack.net">
                  </div>
                  <div class="col-md-12">
                    <label>Location:</label>
                    <input type="text" name="location" value="Melbourne">
                  </div>
                  <div class="col-md-12">
                    <label>Website:</label>
                    <input type="text" name="website" value="http://mrare.co">
                  </div>
                  <div class="col-md-12">
                    <label>Bio:</label>
                    <textarea rows="4" name="bio"></textarea>
                  </div>
                  <div class="col-md-12">
                    <div class="input-checkbox input-checkbox--switch">
                      <input type="checkbox" name="public-profile" id="input-assigned-0">
                      <label for="input-assigned-0"></label>
                    </div>
                    <span>Allow my profile to be viewable by guests</span>
                  </div>
                  <div class="col-lg-3 col-md-4">
                    <button type="submit" class="btn btn--primary type--uppercase">Save Profile</button>
                  </div>
                </div>
              </form>
            </div>
            <div v-if="tab == 1" class="account-tab">
              <h4>Email Notifications</h4>
              <p>Select the frequency with which you'd like to recieve product summary emails:</p>
              <form>
                <div class="boxed bg--secondary boxed--border row">
                  <div class="col-4 text-center">
                    <div class="input-radio">
                      <span>Never</span>
                      <input type="radio" name="frequency" value="never" class="validate-required" id="input-assigned-1">
                      <label for="input-assigned-1"></label>
                    </div>
                  </div>
                  <div class="col-4 text-center">
                    <div class="input-radio checked">
                      <span>Weekly</span>
                      <input type="radio" name="frequency" value="weekly" class="validate-required" id="input-assigned-2">
                      <label for="input-assigned-2"></label>
                    </div>
                  </div>
                  <div class="col-4 text-center">
                    <div class="input-radio">
                      <span>Monthly</span>
                      <input type="radio" name="frequency" value="monthly" class="validate-required" id="input-assigned-3">
                      <label for="input-assigned-3"></label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4 col-md-5">
                    <button type="submit" class="btn btn--primary type--uppercase">Save Preferences</button>
                  </div>
                </div>
              </form>
            </div>
            <div v-if="tab == 2"  class="account-tab">
              <h4>Billing Details</h4>
              <div class="boxed boxed--border bg--secondary">
                <h5>Payment Methods</h5>
                <hr>
                <form>
                  <ul>
                    <li class="row">
                      <div class="col-md-6">
                        <p>
                          <i class="material-icons">credit_card</i>
                          <span> Mastercard ending in <strong>4722</strong></span>
                        </p>
                      </div>
                      <div class="col-md-3 text-right text-left-xs">
                        <button type="submit" class="btn bg--error">Remove</button>
                      </div>
                      <div class="col-md-3 text-right text-left-xs">
                        <button type="submit" class="btn">Edit</button>
                      </div>
                    </li>
                  </ul>
                  <hr>
                  <button type="submit" class="btn">Add New Method</button>
                </form>
              </div>
            </div>
            <div v-if="tab == 3" class="account-tab">
              <h4>Password</h4>
              <p>Passwords must be at least 6 characters in length.</p>
              <form>
                <div class="row">
                  <div class="col-12">
                    <label>Old Password:</label>
                    <input type="password" name="old-password">
                  </div>
                  <div class="col-md-6">
                    <label>New Password:</label>
                    <input type="password" name="new-password">
                  </div>
                  <div class="col-md-6">
                    <label>Retype New Password:</label>
                    <input type="password" name="new-password-confirm">
                  </div>
                  <div class="col-lg-3 col-md-4">
                    <button type="submit" class="btn btn--primary type--uppercase">Save Password</button>
                  </div>
                </div>
              </form>
            </div>
            <div v-if="tab == 4" class="account-tab">
              <h4>Delete Account</h4>
              <p>Permanently remove your account using the button below. Warning, this action is permanent.</p>
              <form>
                <button type="submit" class="btn bg--error type--uppercase">Delete Account</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import firebase from 'firebase'
import db from '@/firebase/firestore'
export default {
  name: 'Account',
  props: ['user'],
  data() {
    return {
      tab: 0
    }
  },
  methods: {
    logout() {
      firebase.auth().signOut()
      this.go('Login')
    }
  }
}
</script>