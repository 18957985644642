<template>
  <div class="landing">
    <!-- Hero -->
    <section class="switchable bg--primary">
      <div class="container">
        <div class="row justify-content-between">
          <div class="col-lg-5 col-md-7">
            <div class="mt--2">
              <h1>Complete system for teaching kids to code</h1>
              <p class="lead">Backed by science, loved by kids</p>
              <div v-if="user">
                <div class="alert alert-success" style="color: white">
                  <!-- <strong>Thanks {{ user.displayName }}!</strong><br> You will receive an email once Bleep Bloop goes Alpha! -->
                  <!-- <strong>Thanks {{ user.displayName.split(' ')[0] }}!</strong><br> You will receive an email once Bleep Bloop goes Alpha! -->
                </div>
              </div>
              <div v-else>
                <p>Join our mailing list to take part in the Bleep Bloop alpha launch!</p>
                <span class="btn btn--primary type--uppercase mt-3" @click="googleStart()">
                  <span class="btn__text">Register for Alpha</span>
                </span>
                <span class="block type--fine-print"></span>
              </div>
              <span v-if="feedback" style="color: white">Error: {{ feedback }}</span>
            </div>
          </div>
          <div class="col-lg-7 col-md-5 col-12 d-flex">
            <div class="image-container">
              <img alt="Image" src="@/assets/images/bleep-bloop-right.png" />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import firebase from 'firebase'
import db from '@/firebase/firestore'
export default {
  name: 'Landing',
  data() {
    return {
      email: 'test',
      spots: 100,
      feedback: '',
      user: null
    }
  },

  created() {
    firebase.auth().onAuthStateChanged( firebaseUser => {
      if (firebaseUser) {
        this.user = firebaseUser
      } else {
        // ------------ Logged Out ------------
        if (this.user) {
          this.user = null
        }
      }
    })
  },

  methods: {
    logout() {
      console.log('lo')
      firebase.auth().signOut().then(function() {
        console.log('Signed Out');
      }, function(error) {
        console.error('Sign Out Error', error);
      });
    },
    googleStart() {
      this.feedback = ''
      let provider = new firebase.auth.GoogleAuthProvider()
      // provider.addScope('https://www.googleapis.com/auth/contacts.readonly')
      firebase.auth().languageCode = 'en'
      firebase.auth().signInWithPopup(provider)
      .then( credential => {
        if (credential.additionalUserInfo.isNewUser) {
          
        } else {
          
        }
      })
      .catch( err => {
        if (err.code == 'auth/popup-closed-by-user') {
          this.feedback = 'Google authentication popup closed.'
        } else {
          this.feedback = err.message
        }
      })
    }
  }
}
</script>

<style lang="scss">
  // @import '@/assets/scss/stack-interface.scss';
  // @import '@/assets/scss/socicon.scss';
  // @import '@/assets/scss/lightbox.min.scss';
  // @import '@/assets/scss/bootstrap.scss';
  // @import '@/assets/scss/flickity.scss';
  // @import '@/assets/scss/stack-interface.scss';
  // @import '@/assets/scss/jquery.steps.scss';
  // @import '@/assets/scss/theme.scss';
  // @import '@/assets/scss/custom.scss';
  .image-container {
    height: 400px;
    display: flex;
    justify-content: center;
    width: 100%;
  }
</style>
